import { ContentLang, ContentLangCode } from '~/enums'
import makeServices from '~/services'
import WidgetCreator from '~/factories/WidgetCreator'

const debug = false
const watchBody = true
const widgetClassName = 'sp-content-widget'

debug && console.info('SP: Script loaded');

const initialize = () => {
  debug && console.info('SP: Initializing widget watcher');
  const client = CLIENT
  const locale = (typeof client !== 'undefined' && client.locale) || 'en-GB'
  const languageId = ContentLang[ContentLangCode.getName(ContentLangCode[locale])]

  const trackerOptions = {
    trackerType: 'gtag',
    gaCode: client.gaCode,
  }

  const samplingOptions = {
    enabled: true,
    client,
    applicationId: 14, // SP Embed application equal to '14'
    channelId: 6,
    languageId,
    apiUrl: process.env.AGENTS_API_URL || 'https://api.sp-agents.com/'
  }

  const renderAll = () => {
    document.querySelectorAll(`.${widgetClassName}`).forEach((el) => {
      debug && console.info('SP: Widget Found', el.dataset);
      const { widgetType, contentId, apiUrl, startUrl, apiType, token, languageId, advisorFlow, renderType, buttonText, options } = el.dataset

      const services = makeServices({ token, channelId: 6, client, clientId: client.id, apiUrl, apiType, trackerOptions, samplingOptions })
      const widgetCreator = new WidgetCreator(services)
      const widget = widgetCreator.create(widgetType, {
        'content-id': contentId,
        'language-id': languageId,
        'advisor-flow': advisorFlow,
        'start-url': startUrl,
        'render-type': renderType,
        'button-text': buttonText,
        options: options
      })
      widget.render(el)
    })
  }

  renderAll()

  if (watchBody && typeof MutationObserver !== 'undefined') {
    const observer = new MutationObserver((mutationList, observer) => {
      for (const mutation of mutationList) {
        if (mutation.type === 'childList' && mutation.addedNodes.length) {
          const widgets = document.querySelectorAll(`.${widgetClassName}`)
          renderAll(widgets)
        }
      }
    })

    const config = { childList: true, subtree: true }

    observer.observe(document.body, config)
  }
  debug && console.info('SP: MutationObserver is now watching the body for new widgets.');
};

// Check the current ready state of the document
if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', () => {
    debug && console.info('SP: DOMContentLoaded event fired');
    initialize();
  });
} else {
  debug && console.info('SP: DOM already loaded');
  initialize();
}
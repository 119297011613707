import Enum from './Enum'

const RenderType = {
  InPlace: 1, // render widget content where the corresponding div is placed
  Widget: 2, // render as a widget (floating button)
}

Enum.makeEnum(RenderType, RenderType)

export default RenderType
